body {
  margin: 0;
  padding: 0;
  font-family: 'JetBrains Mono', monospace;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.coming-soon-container {
  text-align: center;
}

.logo-container {
  display: inline-block;
  text-align: center;
}

.logo {
  max-width: 20%;
  height: auto;
}

h1 {
  margin-top: 20px;
  font-size: 24px;
  color: #333;
}
